/*--------------------------------------------------------------
# Custom scripts for Image carousel block
--------------------------------------------------------------*/

const imgSlider = document.querySelectorAll('.image-carousel .swiper');
const imgSliderNext = document.querySelectorAll('.image-swiper-next');
const imgSliderPrev = document.querySelectorAll('.image-swiper-prev');

for( i = 0; i < imgSlider.length; i++ ) {
    // loop through every example of the slider and add a dynamic class
    // then init slider, pagination and nav arrows based on that new dynamic class
    imgSlider[i].classList.add('swiper-' + i);
    imgSliderNext[i].classList.add('image-swiper-next-' + i);
    imgSliderPrev[i].classList.add('image-swiper-prev-' + i);

    var initImgSlider = new Swiper('.image-carousel .swiper-' + i, {
        grabCursor: true,
        slidesPerView: 2,
        spaceBetween: 24,
        loop: false,
        autoHeight: false,
        watchOverflow: true,
        // commenting out autoplay until play/pause buttons can be figured out
        // autoplay: {
        //     delay: 2500,
        //     disableOnInteraction: false,
        //     pauseOnMouseEnter: true,
        // },
        navigation: {
            nextEl: '.image-swiper-next-' + i,
            prevEl: '.image-swiper-prev-' + i,
        },
        breakpoints: {
            575: {
                slidesPerView: 3,
            },
            768: {
                slidesPerView: 4,
            },
            992: {
                slidesPerView: 5,
            },
            1200: {
                slidesPerView: 6,
            }
        }
    });

    // this is the basics for playing and pausing Swiper autoplay but need a way to target specific slider
    // jQuery('.pause-btn').on('click', function(){
    //     console.log('pause clicked');
    //     initImgSlider.autoplay.stop();
    // });
    
    // jQuery('.play-btn').on('click', function(){
    //     console.log('play clicked');
    //     initImgSlider.autoplay.start();
    // });
}

