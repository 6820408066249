/*--------------------------------------------------------------
# Custom scripts for card slider - used in related posts and card carousel
--------------------------------------------------------------*/

const cardSlider = document.querySelectorAll('.card-slider .swiper');
const cardSliderPagination = document.querySelectorAll('.card-swiper-pagination');
const cardSliderNext = document.querySelectorAll('.card-swiper-next');
const cardSliderPrev = document.querySelectorAll('.card-swiper-prev');

for( i = 0; i < cardSlider.length; i++ ) {
    // loop through every example of the slider and add a dynamic class
    // then init slider, pagination and nav arrows based on that new dynamic class
    cardSlider[i].classList.add('swiper-' + i);
    cardSliderPagination[i].classList.add('card-swiper-pagination-' + i);
    cardSliderNext[i].classList.add('card-swiper-next-' + i);
    cardSliderPrev[i].classList.add('card-swiper-prev-' + i);

    var initCardSlider = new Swiper('.card-slider .swiper-' + i, {
        simulateTouch: false,
        grabCursor: false,
        slidesPerView: 1.1,
        spaceBetween: 24,
        // Pagination toggle buttons
        pagination: {
            el: '.card-swiper-pagination-' + i,
            clickable: true,
        },
        // Navigation arrows
        navigation: {
            nextEl: '.card-swiper-next-' + i,
            prevEl: '.card-swiper-prev-' + i,
        },
        breakpoints: {
            576: {
                slidesPerView: 2,
                slidesPerGroup: 2, // how many to slide at a time
            },
            768: {
                slidesPerView: 3,
                slidesPerGroup: 3, // how many to slide at a time
            }
        }
    });
}
